import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IEntity } from '@lancelot-frontend/api';
import { AppService } from '../app.service';
import { UserRightsService } from '../user/user-rights.service';
import { AdminService } from './admin.service';

export const canAccessAdmin: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const userRightsService = inject(UserRightsService);

  return app.addRouterGuard(
    'canAccessAdmin',
    url,
    userRightsService.canAccessAdmin$.pipe(
      filter((canAccessAdmin) => canAccessAdmin !== undefined),
      map((canAccessAdmin) => {
        if (!canAccessAdmin) {
          return router.createUrlTree(['auth', 'missing-permission']);
        }

        return true;
      }),
    ),
    { comeBackToRefusedUrlOnValueChange: true },
  );
};

export const canAccessEntity: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const adminService = inject(AdminService);

  let entityFFBCode: IEntity['ffbCode'] | undefined = undefined;
  let child = route.root;

  while (entityFFBCode === undefined && child?.firstChild) {
    entityFFBCode = child.params.entityFFBCode;
    child = child.firstChild;
  }

  return app.addRouterGuard(
    'canAccessEntity',
    url,
    adminService.canAccessEntity$(entityFFBCode).pipe(
      map((canAccessEntity) => {
        if (!canAccessEntity) {
          return router.createUrlTree(['auth', 'missing-permission']);
        }

        return true;
      }),
    ),
    { comeBackToRefusedUrlOnValueChange: true },
  );
};

export const canAccessCompetitionSettings: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const userRightsService = inject(UserRightsService);
  const adminService = inject(AdminService);

  let entityFFBCode: IEntity['ffbCode'] | undefined = undefined;
  let child = route.root;

  while (entityFFBCode === undefined && child?.firstChild) {
    entityFFBCode = child.params.entityFFBCode;
    child = child.firstChild;
  }

  return app.addRouterGuard(
    'canAccessCompetitionSettings',
    url,
    adminService.entities$
      .pipe(
        filter((entities): entities is IEntity[] => !!entities),
        map((entities) =>
          entities.find(({ ffbCode }) => ffbCode === entityFFBCode),
        ),
        switchMap((currentEntity) =>
          currentEntity
            ? userRightsService
                .canAccessCompetitionSettings(currentEntity)
                .pipe(filter(() => canAccessCompetitionSettings !== undefined))
            : of(false),
        ),
      )
      .pipe(
        map((canAccessCompetitionSettings) => {
          if (!canAccessCompetitionSettings) {
            return router.createUrlTree(['auth', 'missing-permission']);
          }

          return true;
        }),
      ),
    { comeBackToRefusedUrlOnValueChange: true },
  );
};

export const canAccessUsers: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const userRightsService = inject(UserRightsService);
  const adminService = inject(AdminService);

  let entityFFBCode: IEntity['ffbCode'] | undefined = undefined;
  let child = route.root;

  while (entityFFBCode === undefined && child?.firstChild) {
    entityFFBCode = child.params.entityFFBCode;
    child = child.firstChild;
  }

  return app.addRouterGuard(
    'canAccessUsers',
    url,
    adminService.entities$
      .pipe(
        filter((entities): entities is IEntity[] => !!entities),
        map((entities) =>
          entities.find(({ ffbCode }) => ffbCode === entityFFBCode),
        ),
        switchMap((currentEntity) =>
          currentEntity
            ? userRightsService
                .canAccessUsers(currentEntity)
                .pipe(filter(() => canAccessUsers !== undefined))
            : of(false),
        ),
      )
      .pipe(
        map((canAccessUsers) => {
          if (!canAccessUsers) {
            return router.createUrlTree(['auth', 'missing-permission']);
          }

          return true;
        }),
      ),
    { comeBackToRefusedUrlOnValueChange: true },
  );
};

export const canAccessEntities: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const userRightsService = inject(UserRightsService);
  const adminService = inject(AdminService);

  let entityFFBCode: IEntity['ffbCode'] | undefined = undefined;
  let child = route.root;

  while (entityFFBCode === undefined && child?.firstChild) {
    entityFFBCode = child.params.entityFFBCode;
    child = child.firstChild;
  }

  return app.addRouterGuard(
    'canAccessEntities',
    url,
    adminService.entities$
      .pipe(
        filter((entities): entities is IEntity[] => !!entities),
        map((entities) =>
          entities.find(({ ffbCode }) => ffbCode === entityFFBCode),
        ),
        switchMap((currentEntity) =>
          currentEntity
            ? userRightsService
                .canAccessEntitiesSettings(currentEntity)
                .pipe(filter(() => canAccessEntities !== undefined))
            : of(false),
        ),
      )
      .pipe(
        map((canAccessEntities) => {
          if (!canAccessEntities) {
            return router.createUrlTree(['auth', 'missing-permission']);
          }

          return true;
        }),
      ),
    { comeBackToRefusedUrlOnValueChange: true },
  );
};
